<template>
<section>
    <v-container>
        <v-row justify="start">
          <v-toolbar-title 
            class="grey--text text--darken-4 font-weight-black my-5 ml-12"
            >
            Homes List
          </v-toolbar-title>
          <v-spacer />
           <v-btn 
            class="grey--text text--darken-4 font-weight-black my-5 ml-12"
            v-on:click="refresh_home_list"
            >
            Refresh
          </v-btn>
        </v-row>
        <paginate
            name="homes"
            :list="homes"
            :per="26"
        >
        <v-row justify="center">
             <v-col cols="6" sm="4" v-for="home in paginated('homes')"
         :key="home.id">
               <v-hover v-slot:default="{ hover }">
                 <v-card
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="residentList(home.code)"
                  style="cursor: pointer"
                >
                  <v-img
                      class="white--text align-end img-rounded"
                      height="350" width="400"
                      contain
                      v-bind:src="image_url(home.photo)"
                  >
                  </v-img>
                  <p @click="residentList(home.code)" class='mx-12 px-12'> {{home.name}} </p>
                 </v-card>
              </v-hover>
            </v-col>
        </v-row>
        </paginate>
        <paginate-links
            for="homes"
            :show-step-links="true"
        ></paginate-links>
    </v-container>
</section>
</template>
<script>

import { mapActions } from 'vuex'
import homesApi from '../../services/api/Homes';

export default {
    name: 'home',
    components: {
    },
    data () {
        return {
            homes: [],
            totalHomes: 0,
            paginate: ['homes'],
            search: '',
            options: {
              page: 0,
              itemsPerPage: 26,
            },
        }
    },
    computed: {
        searchQuery (){
          if(this.search){
          return this.homes.filter((item)=>{
            return item.name.startsWith(this.search);
          })
          }else{
            return this.homes;
          }
         }
    },
    created() {
        this.get_home_list();
    },
    methods: {
        ...mapActions([
            'refresh_home_list',
        ]),
        image_url: function(image) {
         return 'https://ocrb.johnsoncaregroup.com/storage/images/homes/'+ image
        },
         get_home_list () {
          this.loading = true
          if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
              var homecode = ''
          }
          else{
              homecode = localStorage.getItem('homeCode')
          }
          return new Promise((resolve, reject) => {
              const { page, itemsPerPage } = this.options
              homesApi.getHomes(page, itemsPerPage) 
                .then(homes => {
                    if (homecode.length > 0 ){
                        this.homes = homes.filter(home => home.code==homecode)
                    }
                    else{
                      this.homes = homes
                    }
                })
                .catch(error => {
                    return reject(error)
                })
          })
        },
        residentList(homeCode){
            this.$router.push({ name: 'residentList', params: {homeCode:homeCode}})
        }
    },
    watch: {
    options: {
          handler () {
            this. get_home_list () 
                .then(data => {
                    this.homes = data.result
                })
          },
          deep: true,
        },
        dialog (val) {
          val || this.close()
        },
  }
}
</script>
<style lang="scss">
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
.paginate-links.items {
  user-select: none;
  a {
    cursor: pointer;
  }
  li.active a {
    font-weight: bold;
  }
  li.next:before {
    content: ' | ';
    margin-right: 13px;
    color: #ddd;
  }
  li.disabled a {
    color: #ccc;
    cursor: no-drop;
  }
}

a {
  color: #42b983;
}
</style>
